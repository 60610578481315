import {Node} from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import {
	IBuyboxAddToCartViewProps,
	IBuyboxAddToOrderTemplateViewProps,
	IBuyboxAddToWishlistViewProps,
	IBuyboxKeyInPriceViewProps,
	IBuyboxProductConfigureDropdownViewProps,
	IBuyboxProductConfigureViewProps,
	IBuyboxProductQuantityViewProps,
	IBuyboxShopSimilarLookViewProps
} from '@msdyn365-commerce-modules/buybox/src/common';
import { IBuyboxViewProps } from '@msdyn365-commerce-modules/buybox/src/modules/buybox/./buybox';
import {
	IBuyboxFindInStoreViewProps
} from '@msdyn365-commerce-modules/buybox/src/modules/buybox/components/buybox-find-in-store';
import { AttributeValue, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import {getAttributeValuesAsync} from "@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g";
import {ReactNode, useState} from 'react';


/* Private Functions */
const _getExtensionProperties = (product: SimpleProduct|undefined): null|{} => {
	if (product === undefined || !product.ExtensionProperties) return null;
	//we have product data, proceed to extract the usable attributes
	let count = 0;
	let final = {};		//final return for our data

	while (product.ExtensionProperties && product.ExtensionProperties[count] !== undefined){
		const ep_key = product.ExtensionProperties[count].Key;
		let ep_val: any;

		Object.keys(product.ExtensionProperties[count].Value!).forEach((item) => {
			if (product.ExtensionProperties![count].Value![item] !== undefined){
				ep_val = product.ExtensionProperties![count].Value![item];
			}
		})
		if (ep_key && ep_val) final[ep_key] = ep_val;	//false switches don't pass this
		count++;
	}
	return final;
}
const _renderSearchName = (ExtensionProps: {}|null): JSX.Element|null => {
	if (ExtensionProps && ExtensionProps["SearchName"]){
		return <h2 className="ms-buybox__search-name h5 text-muted">{ExtensionProps["SearchName"]}</h2>;
	} else {
		return null
	}
}
const _renderLevelScale = (ExtensionProps: {}|null): JSX.Element|null => {
	if (ExtensionProps && ExtensionProps["LevelId"]){
		return (
			<div className={'bb_product-level ' + ExtensionProps["LevelId"].toLowerCase()}>{ExtensionProps["LevelId"]}</div>
		)
	} else {
		return null
	}
};
const _formatPricing = (PriceRaw:number|null, ExtensionProps: {}|null, price: ReactNode|undefined, channelId:number) => {
	//if there's no price object, price number, EP, or Suppress flag is here, show nothing
	if(!price || !PriceRaw) return null;
	if(ExtensionProps && ExtensionProps['IsSupressPrice']) return null;
	if([5637145352,5637145370].includes(channelId)){
		return (
			<>
				<div className="d-flex pricing">{price}</div>
				<p className="small">Price includes VAT</p>
			</>
		)
	} else {
		return <div className="d-flex pricing">{price}</div>
	}
}
const _renderSocialShare = (socialShare: React.ReactNode[]): JSX.Element|null => {
	if (!socialShare || socialShare.length === 0) return null;
	return (<>
		{socialShare[0]}
		<hr className="my-3"/>
	</>);
};
const _renderConfigure = (configure: IBuyboxProductConfigureViewProps, ExtensionProps: Object|null): JSX.Element => {
	const {ContainerProps, dropdowns} = configure;
	return (
		<Node {...ContainerProps}>
			{dropdowns.map((dropdown: IBuyboxProductConfigureDropdownViewProps, i: number) => {
				return _renderConfigureDropdown(dropdown, ExtensionProps, i);
			})}
		</Node>
	);
};
const _renderConfigureDropdown = (
	dropdown: IBuyboxProductConfigureDropdownViewProps, ExtensionProps: Object|null, count: number,
): JSX.Element => {
	const dimensionOverrideKeys = ['DimensionOne', 'DimensionThree', 'DimensionFour'];
	const {ContainerProps, LabelContainerProps, heading, errors, select} = dropdown;
	//if we have an overide use it, otherwise use the default
	const headingLabel = (ExtensionProps && ExtensionProps[dimensionOverrideKeys[count]]) || heading;

	return (
		<Node {...ContainerProps}>
			<Node {...LabelContainerProps}>{headingLabel}{errors}</Node>
			{select}
		</Node>
	);
}
const _renderKeyInPrice = (keyInPrice: IBuyboxKeyInPriceViewProps): JSX.Element => {
	const { ContainerProps, LabelContainerProps, heading, input } = keyInPrice;
	return (
		<Node {...ContainerProps}>
			<Node {...LabelContainerProps}>{heading}</Node>
			{input}
		</Node>
	);
};
const _renderQuantity = (quantity: IBuyboxProductQuantityViewProps): JSX.Element => {
	const { ContainerProps, LabelContainerProps, heading, input, errors } = quantity;
	return (
		<Node {...ContainerProps}>
			<Node {...LabelContainerProps}>{heading}{errors}</Node>
			{input}
		</Node>
	);
};
const _renderAddToCart = (addToCart: IBuyboxAddToCartViewProps): JSX.Element => {
	const { ContainerProps, errorBlock, button } = addToCart;
	return <Node {...ContainerProps}>{errorBlock} {button}</Node>;
};
const _renderAddToOrderTemplate = (addToOrderTemplate: IBuyboxAddToOrderTemplateViewProps): JSX.Element => {
	const { ContainerProps, errorBlock, button } = addToOrderTemplate;
	return <Node {...ContainerProps}>{errorBlock} {button}</Node>;
};
const _renderAddToWishlist = (addToWishlist: IBuyboxAddToWishlistViewProps): JSX.Element => {
	const { ContainerProps, errorBlock, button } = addToWishlist;
	return <Node {...ContainerProps}>{errorBlock} {button}</Node>;
};
const _renderFindInStore = (findInStore: IBuyboxFindInStoreViewProps): JSX.Element => {
	const {
		ContainerProps,
		storeSelector,
		heading,
		description,
		errors,
		button,
		modal,
		productPickupOptionList
	} = findInStore;
	return (
		<Node {...ContainerProps}>
			{storeSelector} {heading} {productPickupOptionList} {description} {errors} {button} {modal}
		</Node>
	);
};
const _renderShopSimilarItem = (shopSimilarItem: IBuyboxShopSimilarLookViewProps): JSX.Element => {
	const { ContainerProps, errors, input } = shopSimilarItem;
	return <Node {...ContainerProps}>{errors} {input}</Node>;
};
const _renderHtmlField = (fieldKey:string, ExtensionProps:Object|null):JSX.Element|null => {
	if(ExtensionProps === null) return null;
	if(ExtensionProps[fieldKey]){

		return (
			<div className="mb-3 mb-lg-5">
				<h2 className="mb-2">{fieldKey.replace('Product', '')}</h2>
				<div dangerouslySetInnerHTML={{ __html:ExtensionProps[fieldKey] }} />
			</div>
		)
	} else {
		return null;
	}
}

//Injection of deleted dimensions
function inject_dimensions(dimensions: any, type_dimension: any) {
	let dropDowns = document.getElementsByClassName('ms-buybox__dropdown');
	//Get the dropdown html by using ID
	const valves = document.getElementById('valves');
	const material = document.getElementById('material');

	for (let i = 0; i < dropDowns.length; i++) {
		let dropDown_for = dropDowns[i].firstElementChild?.getAttribute('for');
		let dimension_type;
		if (dropDown_for != null) {
			let search_against = 'ms-buybox__dropown-quantity-input-';
			dimension_type = dropDown_for.slice(dropDown_for.indexOf(search_against) + search_against.length);
			if (dimensions !== undefined && dimension_type == type_dimension.toString()) {
				let $element = dropDowns[i].lastChild as HTMLDivElement;
				if ($element.classList.contains('msc-swatch-container')) {
					//SWATCH
					let $options = $element.children as HTMLCollection;
					for (let z = 0; z < dimensions.length; z++) {
						let is_exist = false;
						for (let y = 0; y < $options.length; y++) {
							if ($options[y].textContent == dimensions[z].VALUE) {
								is_exist = true;
								//Remove duplicate
								if ($options[y].textContent == material?.textContent) {
									material && material.remove();
								}
							}
						}
						if (!is_exist) {
							//PUSH the value
							let option_elem = document.createElement('button');
							option_elem.textContent = dimensions[z].VALUE;
							option_elem.setAttribute('disabled', '');
							option_elem.classList.add('msc-swatch-container__item');
							option_elem.classList.add('msc-swatch-container__item__unselected');
							option_elem.classList.add('msc-swatch-container__item__uncolored');
							option_elem.setAttribute('id', 'material');
							$element.appendChild(option_elem);
						}
					}
				} else {
					//DROPDOWN
					let $select = dropDowns[i].lastChild as HTMLSelectElement;
					let $options = $select.options as HTMLOptionsCollection;
					for (let z = 0; z < dimensions.length; z++) {
						let is_exist = false;
						for (let y = 0; y < $options.length; y++) {
							if ($options[y].text == dimensions[z].VALUE) {
								is_exist = true;
								//Remove duplicate
								if ($options[y].text == valves?.textContent) {
									valves && valves.remove();
								}
							}
						}
						if (!is_exist) {
							//PUSH the value in the dropDown
							let option_elem = document.createElement('option');
							option_elem.text = dimensions[z].VALUE;
							option_elem.setAttribute('disabled', '');
							option_elem.setAttribute('id', 'valves');
							$select.options.add(option_elem);
						}
					}
				}
			}
		}
	}
}

/* Main Exported Function */
const BuyboxView: React.FC<IBuyboxViewProps> = props => {
	/* Only destructuring repeated or complicated props
	 * Totally removed MediaGalleryContainerProps & description (not using OOtB) */
	const {
		addToCart,
		addToOrderTemplate,
		addToWishlist,
		configure,
		findInStore,
		keyInPrice,
		price,
		ProductInfoContainerProps,
		quantity,
		shopSimilarLook,
		shopSimilarDescription,
	} = props;

	const ExtensionProps = _getExtensionProperties(props.data.product.result);
	//Attribute Function Chain
	const [productAttribute, setProductAttribute] = useState<AttributeValue[]>([]);
	const _getAttributesAsync = async (props: IBuyboxViewProps) => {
		const getAttribute:AttributeValue[] = await getAttributeValuesAsync(
			{ callerContext: props.context.actionContext },
			props.data.product.result?.RecordId!,
			props.context.request.apiSettings.channelId,
			props.context.request.apiSettings.catalogId
		);
		setProductAttribute(getAttribute);
	}
	const renderProductAttributes = () => {
		return (
			<>
				<h2 className="ms-product-specification__heading">Product Attributes</h2>
				<table className="ms-product-specification__table table-striped table-bordered">
					<tbody>
					{productAttribute.map(attr => (
						<tr className="ms-product-specification__table-row">
							{attr.TextValue !== '' && (
								<>
									<th scope="row">{attr.Name}</th>
									<td>{attr.TextValue}</td>
								</>
							)}
						</tr>
					))}
					</tbody>
				</table>
			</>
		);
	};
	const PriceRaw = ( props.data.product.result?.Price || null);

	// Get Attribute Call
	React.useEffect(() => {
		_getAttributesAsync(props);
		props.data.productDimensions.result?.forEach(dimension => {
			let dimension_type = dimension.DimensionTypeValue;
			let dimension_values: any = '';
			dimension.ExtensionProperties?.forEach(value => {
				if (value.Key == 'AllDimensions') dimension_values = value.Value?.StringValue;
			});
			if (dimension_values !== '') {
				dimension_values = JSON.parse(dimension_values);
				inject_dimensions(dimension_values, dimension_type);
			}
		});
	});

	return (
		<div id="pdp" className="row mb-3 mb-lg-5">
			<div className="col-sm-7 col-lg-8 pos-initial">
				<div className="ms-buybox__media-gallery">{props.mediaGallery}</div>
				<Node {...ProductInfoContainerProps}>
					<div className="ms-buybox__details">
						{props.title}
						{_renderSearchName(ExtensionProps)}
						{_renderLevelScale(ExtensionProps)}
						{_formatPricing(PriceRaw, ExtensionProps, price, props.context.request.apiSettings.channelId)}
						{_renderSocialShare(props.slots && props.slots.socialShare)}
						{configure && _renderConfigure(configure, ExtensionProps)}
						{props.unitOfMeasure}
						{props.bulkPurchaseLink}
						{/*{description}*/}
						{props.rating}
						{keyInPrice && _renderKeyInPrice(keyInPrice)}
						{quantity && _renderQuantity(quantity)}
						{props.inventoryLabel}
						{addToCart && _renderAddToCart(addToCart)}
						{findInStore && _renderFindInStore(findInStore)}
						{addToOrderTemplate && _renderAddToOrderTemplate(addToOrderTemplate)}
						{addToWishlist && _renderAddToWishlist(addToWishlist)}
						{shopSimilarLook && _renderShopSimilarItem(shopSimilarLook)}
						{shopSimilarDescription && _renderShopSimilarItem(shopSimilarDescription)}
						<hr className="my-3"/>
						<a className="msc-btn w-100" href="/dealer-locator">Find a Dealer</a>
					</div>
				</Node>
				{['ProductDescription', 'ProductFeatures', 'ProductSpecification'].map((item) => {
					return _renderHtmlField(item, ExtensionProps)
				})}
				{productAttribute && renderProductAttributes()}
			</div>
		</div>
	)
};
export default BuyboxView;