import * as React from 'react';
import {IBrandHeroData} from './brand-hero.data';
import {IBrandHeroProps} from './brand-hero.props.autogenerated';
import {IModuleProps, INodeProps} from "@msdyn365-commerce-modules/utilities";
import * as Msdyn365 from "@msdyn365-commerce/core";
import classnames from 'classnames';
import {IContentBlockFullConfig} from "@msdyn365-commerce-modules/content-block/src/modules/content-block/content-block";
import {
	armstrong,
	bach,
	conn,
	connselmer,
	king,
	holton,
	leblanc,
	ludwig,
	musser,
	scherl,
	selmer,
	yanagisawa,
	ludwig_musser
} from './svgs/logos';

export interface IBrandHeroViewProps extends IBrandHeroProps<IBrandHeroData> {
	h1Text?: string;
	brandLogo: string;
	className?: string;
	image?: React.ReactNode;
	title?: React.ReactNode;
	brandHeroContainer: IModuleProps;
	imageContainer: INodeProps;
	detailsContainer: INodeProps;
}

/**
 *
 * BrandHero component
 * @extends {React.PureComponent<IBrandHeroProps<IBrandHeroData>>}
 */
class BrandHero extends React.PureComponent<IBrandHeroProps<IBrandHeroData>> {

	public render(): JSX.Element | null {
		const { h1Text, brandLogo, image, className } = this.props.config;
		const contentBlockTitle = h1Text && (
			<Msdyn365.Text
				className='brand-hero__title'
				tag={'h1'}
				text={h1Text}
			/>
		);
		const imageProps = {
			gridSettings: this.props.context.request.gridSettings ?? {},
			imageSettings: image?.imageSettings
			//lazyload: false ??
		};
		//Get the CTA Link builders from content-block.tsx if we need this later. Same for text

		const brandHeroImage = (
			<Msdyn365.Image
				{...image}
				{...imageProps}
				requestContext={this.props.context.request}
				preserveImageSpace={true}
				editProps={{
					key: this.props.config.image ?? {},
					requestContext: this.props.context.request,
					moduleType: this.props.typeName,
					imagePropertyName: 'image',
					moduleId: this.props.id,
					layout: (this.props.config as IContentBlockFullConfig).msdyn365__moduleLayout
				}}
				shouldSkipToMainImage
			/>
		);
		//Set up the array of logos to grab (this is TERRIBLE CODE)
		const logos = {
			armstrong, bach, conn, connselmer, king, holton, leblanc, ludwig, ludwig_musser, musser, scherl, selmer, yanagisawa
		}
		if (!contentBlockTitle){
			this.props.context.telemetry.error('Brand Hero H1 is empty, module wont render.');
			return null;
		}

		const brandHeroBlockProps = {
			...this.props,
			title: contentBlockTitle,
			image: brandHeroImage,
			moduleClass: this.props.config.className,
			brandHeroContainer: {
				moduleProps: this.props,
				className: classnames('brand-hero', className)
			},
			imageContainer: { className: 'brand-hero__image' },
			detailsContainer: { className: 'brand-hero__details' },
			brandLogo: logos[`${brandLogo}`]()
		};
		return this.props.renderView(brandHeroBlockProps) as React.ReactElement;
	}
}
export default BrandHero;