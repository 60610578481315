import * as React from 'react';
import {IBrandHeroViewProps} from './brand-hero';
import {Module, Node} from "@msdyn365-commerce-modules/utilities";

const BrandHeroView: React.FC<IBrandHeroViewProps> = props => {
	const {
		brandHeroContainer,
		imageContainer,
		detailsContainer,
		title,
		brandLogo,
		image
	} = props;

	return (
		<Module {...brandHeroContainer}>
			<Node {...imageContainer}>{image}</Node>
			<Node {...detailsContainer}>
				{brandLogo}
				{title}
			</Node>
		</Module>
	);
};
export default BrandHeroView;