import * as React from 'react';
import { IYoutubeEmbedData } from './youtube-embed.data';
import { IYoutubeEmbedProps } from './youtube-embed.props.autogenerated';
import * as Msdyn365 from '@msdyn365-commerce/core';
import { IModuleProps } from '@msdyn365-commerce-modules/utilities';
import classnames from 'classnames';

export interface IYoutubeEmbedViewProps extends IYoutubeEmbedProps<IYoutubeEmbedData> {
    title?: React.ReactNode;
    text?: React.ReactNode;
    youTubeEmbedContainer: IModuleProps;
    youTubeURL?: string;
    youTubeTitle?: string;
}

/** -YoutubeEmbed component
 * @extends {React.PureComponent<IYoutubeEmbedProps<IYoutubeEmbedData>>}
 */
class YoutubeEmbed extends React.PureComponent<IYoutubeEmbedProps<IYoutubeEmbedData>> {

    public render(): JSX.Element | null {
        const {headingText, headingTag, paragraph, className} = this.props.config;

        const youTubeEmbedTitle = headingText && (
            <Msdyn365.Text className='you-tube-embed__title' tag={headingTag ?? 'h2'} text={headingText}/>
        );
        const youTubeEmbedText = paragraph && (
            <Msdyn365.RichTextComponent text={paragraph} className='you-tube-embed__text'/>
        );
        const youTubeEmbedProps = {
            ...this.props,
            title: youTubeEmbedTitle,
            text: youTubeEmbedText,
            youTubeEmbedContainer: {
                moduleProps: this.props,
                className: classnames('you-tube-embed', className)
            },
            youTubeURL: this.props.config.youTubeURL,
            youTubeTitle: this.props.config.youTubeTitle
        }

        return this.props.renderView(youTubeEmbedProps) as React.ReactElement;
    }
}
export default YoutubeEmbed;