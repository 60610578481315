import * as React from 'react';
import { IYoutubeEmbedViewProps } from './youtube-embed';
import { Module } from '@msdyn365-commerce-modules/utilities';

const YoutubeEmbedView: React.FC<IYoutubeEmbedViewProps> = props => {
	const {
		youTubeEmbedContainer,
		title,
		text,
		youTubeURL,
		youTubeTitle
	} = props;

	return (
		<Module {...youTubeEmbedContainer}>
			<div className="ratio-16x9">
				<iframe src={youTubeURL} title={youTubeTitle} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
			</div>
			{title}
			{text}
		</Module>
	);
}
export default YoutubeEmbedView;